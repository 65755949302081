<template>
    <v-container fluid>
        <!-- v-row class="text-center">
            <v-flex xs12>
                <h1>Reporting</h1>
            </v-flex>
        </v-row -->
        <ShippingForm />
    </v-container>
</template>

<script>
import ShippingForm from '@/components/ShippingForm.vue'

export default {
    name: 'Shipping',
    components: {
        ShippingForm,
    },
}
</script>
